@import url('./colors.css');

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark background with transparency */
  z-index: 999; /* Ensure it sits above other elements */
}

.cart-drawer {
  position: fixed;
  top: 0;
  right: -300px; /* Initially hidden on the right side */
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease;
  z-index: 1000; /* Above the backdrop */
  display: flex;
  flex-direction: column;
}
@media(min-width: 768px) {
  .cart-drawer {
    right: -600px; /* Initially hidden on the right side */
    width: 600px;
  }
}

.cart-drawer.open {
  right: 0; /* Slide in from the right */
}

.cart-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid #ddd;
}
@media(min-width: 768px) {
  .cart-drawer-header {
    padding: 20px;
  }
}
.cart-drawer-header h2 {
  font-size: 25px;
  font-weight: semibold;
}
@media(min-width: 768px) {
  .cart-drawer-header h2 {
    font-size: 32px;
  }
}

.cart-drawer-content {
  overflow-y: auto;
  flex-grow: 1;
  text-align: center;
  font-weight: medium;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.close-button {
  background: #dddddd;
  border-radius: 10px;
  padding: 10px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: normal;
}

.cart-item {
  display: flex;
  margin-bottom: 15px;
  border-bottom: 1px solid #E5E9EB;
}
.coupon-input {
  border: 1px solid #E5E9EB;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  padding: 14px 10px;
  border-radius: 10px;
}
.cart-holder {
  padding: 40px 20px 20px;
  overflow-y: scroll;
}

.cart-item-image {
  width: 60px;
  height: 80px;
  object-fit: cover;
  margin-right: 15px;
}
@media(min-width: 768px) {
  .cart-item-image {
    width: 80px;
    height: 100px;
  }
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-name {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin: 0;
  color: #000;
}
@media(min-width: 768px) {
  .cart-item-name {
    font-size: 18px;
  }
}

.cart-item-weight {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #000;
}

.cart-item-remove-button {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
}

.cart-drawer-quantity {
  width: 100%;
  height: 100%;
}

/* Sticky footer */
.cart-drawer-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
  border-top: 2px solid white;

  /* Ensure the footer stays at the bottom */
  position: relative;
  bottom: 0;
  z-index: 10;
}

.cart-total {
  font-size: 18px;
  font-weight: normal;
  color: #000;
}

.proceed-to-buy-button {
  background-color: #f19e30;
  color: white;
  padding: 12px 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}
@media(min-width: 768px) {
  .proceed-to-buy-button {
    font-size: 16px;
    padding: 12px 26px;
  }
}

.proceed-to-buy-button:hover {
  background-color: #f19e30;
}
.coupons {
  border-bottom: 1px solid #E5E9EB;
  padding: 10px 0 20px; 
}
@media(min-width: 768px) {
  .coupons {
    padding: 20px 0 30px; 
  }
}
.price-details, .total-payable {
  border-bottom: 1px solid #E5E9EB;
}
.empty-cart-holder, .empty-cart {
  height: 100%;
}

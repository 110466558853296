/* Navbar Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.navbar {
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4cbb17;
  transition: background-color 0.3s ease;
  z-index: 11;
  width: 100vw;
}
@media(min-width: 1024px) {
  .navbar {
    background-color: transparent;
    position: fixed;
  }
}

.navbar.scrolled {
  background-color: #4cbb17;
  border-bottom: 0.5px solid #3a8e65; /* Bottom border when scrolled */
}
.navbar.nonbanner {
  position: sticky; /* Change to relative to allow space below */
  background-color: #4cbb17; /* Set a solid background color */
  margin-top: 0; /* Reset margin */
  border-bottom: 0.5px solid #3a8e65; /* Bottom border when scrolled */
}

/* Navbar Logo and Title */
.navbar-logo {
  display: flex;
  align-items: center; /* Ensures logo and text are vertically aligned in a row */
}

.navbar-logo-img {
  height: 50px;
  width: auto;
}

.navbar-links {
  display: flex;
  gap: 15px;
}
.nav-link:hover {
  color: #F19E30;
  border-radius: 5px;
}

.nav-link.active:hover {
  background-color: #3a8e65; /* Keep the same background color */
  color: #f4f9f4; /* Keep the same text color */
}
/* Search Bar */
.navbar-search {
  display: flex;
  align-items: center;
  gap: 2px;
}

.navbar-search-input {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #F19E30; /* Muted dark green */
  border-radius: 4px;
  outline: none;
}

.navbar-search input:focus {
  border-color: #F19E30; /* Slightly darker green when focused */
}

/* Icon Buttons */
.navbar-icons {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff; /* Dark green icons */
}

.icon-button:hover {
  color: #F19E30; /* Slightly darker green on hover */
}

/* Logout Dialog */
.logout-container {
  position: relative;
}

.logout-dialog {
  position: absolute;
  width: auto;
  top: 40px;
  right: auto;
  left: 50px;
  background-color: #e1f0e1; /* Lighter background */
  color: #2c6e49; /* Dark green text */
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
@media(min-width: 1024px) {
  .logout-dialog {
    right: 0;
    width: 100%;
  }
}

.logout-dialog:hover {
  background-color: #d4e6d4; /* Slightly darker light green on hover */
}

/* Search Results */
.search-results {
  position: absolute;
  top: 70px;
  left: 0;
  width: 300px;
  background-color: #f4f9f4;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 20;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.search-result-item img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.search-result-item span {
  color: #F19E30; /* Dark green text */
}

.search-result-item:hover {
  background-color: #e1f0e1; /* Light green hover */
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #F19E30;
  display: none; /* Hidden by default */
}

@media (max-width: 1024px) {
  .menu-button {
    display: block;
    margin-right: 10px;
    background: none; /* Ensure no background */
    border: 1px solid #ccc; /* Add grey border */
    border-radius: 4px; /* Optional: rounded corners */
    cursor: pointer;
    color: #2c6e49;
    padding: 5px 10px; /* Add some padding for better click area */
  }

  .navbar-links {
    display: none; /* Hide links by default on mobile */
    flex-direction: column; /* Stack links vertically */
    background-color: #f4f9f4; /* Same background as navbar */
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    padding: 10px 0;
    z-index: 10;
  }

  .navbar-links.open {
    display: flex; /* Show links when menu is open */
  }

  .navbar-links .nav-link {
    padding: 10px 20px;
    font-size: 18px;
  }
  .navbar-search-input.visible {
    display: flex;
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid #6b8f71; /* Muted dark green */
    border-radius: 4px;
    outline: none;
  }

  .navbar-logo.invisible {
    display: none;
  }
}

@media (max-width: 1024px) {
  /* Adjust the navbar height for smaller screens */
  .navbar {
    height: 60px;
    justify-content: space-between;
    padding: 5px 10px; /* Reduced padding for a compact look */
  }

  /* Adjust logo and title size */
  .navbar-logo-img {
    height: 40px; /* Smaller logo size */
  }

  .navbar-logo-title {
    margin-top: 30px; /* Adjust margin for vertical alignment */
    margin-left: 5px; /* Less margin for tighter spacing */
  }

  .navbar-naroi-text,
  .navbar-sehat-text {
    font-size: 20px; /* Smaller font size for text */
  }

  .navbar-links {
    display: none; /* Hide links by default */
    flex-direction: column; /* Stack links vertically */
    background-color: #4cbb17; /* Background color matches navbar */
    position: absolute;
    top: 60px; /* Position just below the navbar */
    left: 0;
    right: 0;
    padding: 10px 0;
    z-index: 10;
  }

  .navbar-links.open {
    display: flex; /* Show links when the menu is open */
  }

  .navbar-links .nav-link {
    padding: 10px 15px; /* More padding for easier clicking */
    font-size: 16px; /* Smaller font size for links */
  }

  .navbar-search-input.visible {
    display: block; /* Show the search input when toggled */
    padding: 5px 8px;
    font-size: 14px; /* Smaller font size for search input */
    border: 1px solid #6b8f71; /* Muted dark green */
    border-radius: 4px;
    outline: none;
  }

  /* Adjust search results for mobile */
  .search-results {
    width: 90%; /* Full width for better usability */
    right: 5%; /* Center results */
  }

  .search-result-item {
    padding: 8px; /* Smaller padding for items */
  }

  .navbar-icons {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  @media(min-width: 768px) {
    .navbar-icons {
      gap: 8px;
    }
  }
}

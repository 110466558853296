/* src/css/WhyChooseUs.css */
*,
*::before,
*::after {
  box-sizing: border-box;
}

h2 {
  font-size: 2em;
  margin-bottom: 30px;
  color: #36454f; /* Change to your preferred color */
}

/* Home.css */

.home-page-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.featured-products {
  text-align: center;
  margin: 10px 40px;
}

.home-page-product-list {
  display: flex; /* Use Flexbox for product list */
  flex-wrap: wrap; /* Allow products to wrap to the next line */
  justify-content: start; /* Center products horizontally */
  gap: 20px; /* Space between product cards */
  padding: 0 10px; /* Optional padding for small devices */
}

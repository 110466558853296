/* Orders.css */

/* General Styles for Orders Page */
.orders-page {
  padding: 20px; /* Add some padding */
}

/* Page Title */
.orders-page h1 {
  color: #333; /* Darker text color */
  text-align: center; /* Center the title */
}
@media(min-width: 768px) {
  .orders-page h1 {
    margin-bottom: 20px;
  }
}

/* Orders List Styles */
.orders-list {
  display: grid;
  gap: 20px;
  padding: 20px 0;
}

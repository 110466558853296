/* ReviewCard Styles */
@import url('./colors.css');

.review-card {
  width: 100%;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
}

.review-card p {
  font-weight: normal;
  color: #000;
  margin: 8px 0;
}

.review-card h4 {
  font-weight: 500;
  color: #000;
}

.review-card-ratings {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

/* Adjust stars */
.review-card-ratings svg {
  margin-right: 5px;
  font-size: 1.2rem;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

.small-image {
  width: 100%;
  height: 100%;
  max-width: 140px;
  max-height: 80px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 10px;
}

/* src/css/Collapsible.css */
@import url('./colors.css');

.collapsible {
  width: 100%;
  border-bottom: 1px solid #ccc;
  /* border-left: 2px solid red; */
}

.collapsible-title {
  display: flex;
  justify-content: flex-start;
  padding: 6px;
  color: var(--text-color);
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  border-radius: 5px;
  align-items: center;
}
@media(min-width: 768px) {
  .collapsible-title {
    font-size: 16px;
    padding: 12px;
  }
}

.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: 0 8px;
  border-radius: 5px;
}

.collapsible-content.open {
  max-height: 1000px; /* Arbitrarily large value */
  padding: 8px;
}

/* Banner Styles */
.banner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.banner-media-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.banner-media {
  width: 100%;
  height: 100%;
}

.banner-media-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-overlay {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  width: 100vw;
  transform: translate(
    -50%,
    -50%
  ); /* Adjust to center based on its own dimensions */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  color: white; /* Text color */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  z-index: 1;
}

.banner-controls {
  position: absolute; /* Position the controls over the media */
  bottom: 0; /* Move controls to the bottom of the banner */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background for contrast */
  padding: 10px;
  z-index: 10; /* Make sure controls are above the media */
}

.banner-button {
  background: transparent;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  padding: 0 15px;
}

.banner-dots {
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.banner-dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: lightgray;
  cursor: pointer;
  transition: background-color 0.3s;
}

.banner-dot.active {
  background-color: white;
}

.banner-button:hover {
  color: #ecf0f1;
}
.banner-slider .slick-dots {
  bottom: 20px !important;
}
@media (min-width: 768px) {
  .banner-slider .slick-dots {
    bottom: 80px !important;
  }
}

@import url('./colors.css');

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-form {
  background-color: white;
  padding: 30px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 5px;
}
@media (min-width: 768px) {
  .signup-form {
    margin: 0;
    padding: 50px 20px;
  }
}

.signup-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.signup-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.signup-form button {
  width: 100%;
  padding: 10px;
  background-color: #f19e30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signup-form button:hover {
  background-color: #f19d3095;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

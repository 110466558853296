/* Existing styles... */

.product-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding: 20px 14px;
}
@media(min-width: 1024px) {
  .product-details-container {
    flex-direction: row;
    padding: 40px 20px;
  }
}

.product-details-image-container {
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}
@media(min-width: 1024px) {
  .product-details-image-container {
    justify-content: center;
  }
}


.product-details-image {
  width: 300px; /* Make the image take full width of its container */
  height: 300px; /* Maintain the aspect ratio */
  object-fit: cover; /* Ensures the image covers the entire container without stretching */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 20px; /* Add some space below the image */
}
@media(min-width: 1024px) {
  .product-details-image {
    width: 400px; /* Make the image take full width of its container */
    height: 400px;
  }
}

.thumbnail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
@media(min-width: 768px) {
  .thumbnail-container {
    flex-direction: column;
  }
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border 0.3s ease;
}

.thumbnail.active {
  border-color: #f19e30;
}

.product-details-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media(min-width: 1024px) {
  .product-details-carousel-container {
    align-items: center;
  }
}

.product-details-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.product-details-text {
  padding: 10px;
  /* max-height: 550px;
  overflow-y: scroll; */
  flex: 1;
}

.product-reviews {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/* New styles for the review cards section */
.review-cards {
  display: flex; /* Allows for row layout of reviews */
  flex-direction: column; /* Stacks review cards vertically */
  margin-top: 20px; /* Space above review cards */
  gap: 15px; /* Space between review cards */
  max-height: 500px; /* Limit height for the review section */
  overflow-y: scroll; /* Scroll if reviews exceed max height */
}
.star-rating svg {
  font-size: 12px !important;
}
@media(min-width: 768px) {
  .star-rating svg {
    font-size: 20px !important;
  }
}
.variant-tab.active {
  background-color: #f19e30;
}
/* Add this to your CSS file */
.progress-bar {
  width: 100%;
  max-width: 200px;
  background-color: #DDDDDD; /* Light grey background */
  overflow: hidden;
  height: auto;
  min-height: 30px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4CBB17; /* Green color for the filled portion */
  transition: width 0.3s ease-in-out;
  height: auto;
  min-height: 30px;
}

/* Testimonial.css */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Individual Testimonial Overlay */
.testimonial-overlay {
  display: flex; /* Use Flexbox */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100%; /* Take full height of the parent */
  width: 100%; /* Take full width of the parent */
  color: white; /* Text color */
  text-align: center; /* Center text */
}

.testimonial-overlay h2 {
  margin-bottom: 20px; /* Space below the heading */
  font-size: 2rem; /* Larger font size */
  color: white; /* Dark text color */
}
.slick-slide.slick-current .testimonial-slide .testimonial-slide-holder {
  color: #fff;
  background-color: #4cbb17;
}
.slick-dots li button::before {
  display: none;
}

.slick-dots {
  bottom: -30px !important;
}
@media(min-width: 768px) {
  .slick-dots {
    bottom: -40px !important;
  }  
}
@media(min-width: 1024px) {
  .slick-dots {
    bottom: -60px !important;
  }  
}
.slick-dots li {
  background: #b0b0b0;
  width: 12px !important;
  height: 12px !important;
  border-radius: 100%;
  transition: width 0.3s ease;
}
.slick-dots .slick-active {
  background-color: #f19e30;
}

.order-card-title {
  display: flex;
  align-items: center;
}

.order-card-image {
  width: 50px;
  height: 70px;
  margin-right: 10px;
  border-radius: 8px;
}
@media(min-width: 768px) {
  .order-card-image {
    width: 80px;
    height: 100px;
  }
}

.order-card-text {
  display: flex;
  flex-direction: column;
}

.order-card-description {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}
.order-card {
  border: 1px solid #e5e9eb;
  border-radius: 8px;
}
.order-card .collapsible {
  border: 0;
}

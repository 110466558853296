@import url('./colors.css');

.footer {
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  color: #faf3e0;
  margin-top: auto;
  box-sizing: border-box;
  width: 100vw;
}

/* .footer-column {
  flex: 1;
} */

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
}
@media(max-width: 1024px) {
  .footer {
    padding: 10px 4vw;
  }
}

@media (max-width: 767px) {
  .footer {
    font-size: 0.5em;
    padding: 10px 10vw;
  }

  .newsletter-input {
    max-width: 100px;
    font-size: 1em;
  }

  .subscribe-button {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 0.5em;
    padding: 4px 1vw;
  }

  .newsletter-input {
    max-width: 80px;
    font-size: 1em;
  }

  .subscribe-button {
    padding: 5px 3px;
    font-size: 1em;
  }
}

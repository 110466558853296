/* src/css/ProductCard.css */
@import url('./colors.css');

.product-card-weight-reviews {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.product-card-weight {
  margin: 0px;
  color: #556b2f;
}

.product-card-ratings-reviews {
  display: flex;
  flex-direction: row;
}

.product-card-reviews {
  margin-left: 10px;
  color: #36454f;
}

/* ContactUs.css */
@import url('./colors.css');

.contactUs-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  align-items: center;
}
@media (min-width: 768px) {
  .contactUs-container {
    width: 80%;
  }
}
@media (min-width: 1024px) {
  .contactUs-container {
    width: 50%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input,
.textarea {
  padding: 10px;
  font-size: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  width: 100%;
}
@media (min-width: 768px) {
  .input,
  .textarea {
    font-size: 16px;
    padding: 14px;
  }
}

.textarea {
  height: 100px;
}

.query-button {
  padding: 10px 10px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 12px;
  font-size: 12px;
  color: #fff;
  background-color: #f19e30;
  border: 1px solid #f19e30;
  cursor: pointer;
}
@media (min-width: 768px) {
  .query-button {
    padding: 14px 20px;
    font-size: 16px;
  }
}

.query-button:hover {
  background-color: transparent;
  color: #f19e30;
}
